#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  font-weight: 400;
  color: black;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;

  background-size: cover;
  background-attachment: fixed;

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  .hero-title {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;
    color: white;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.95;
    overflow: hidden;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 120%;
    position: 120%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  .hero_container {
    position: absolute;
    bottom: 10vh;
    left: 10vh;
    z-index: 5;
  }
  .typing-effect {
    // font-family: "Courier New", Courier, monospace;
    // font-size: 24px;
    // white-space: nowrap;
    // overflow: hidden;
    // border-right: 2px solid;
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;
    color: white;
    animation: blink 0.7s step-end infinite;

    @include respond(tab-land) {
      font-size: 3rem;
    }
    @include respond(tab-port) {
      font-size: 2.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 2.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 1.8rem;
    }
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}
